import { getBackgroundCSS, getBorderCSS, getColorsCSS, getSpaceCSS, getTypoCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { columnGap, rowGap, isContentEqualHight, sliderHeight, paginationColors, paginationHovColors, paginationPadding, paginationSpacing, fImgFitting = 'cover', contentAlign, contentBG, contentPadding, border, sliderPageColor, sliderPageWidth, sliderPageHeight, sliderPageBorder, sliderPrevNextColor, titleTypo, titleColor, titleMargin, metaTypo, metaTextColor, metaLinkColor, metaIconColor, metaColorsOnImage, metaMargin, excerptAlign, excerptTypo, excerptColor, excerptMargin, readMoreAlign, readMoreTypo, readMoreColors, readMoreHovColors, readMorePadding, readMoreBorder } = attributes;

	const mainSl = `#apbAdvancedPosts-${clientId}`;
	const postSl = `${mainSl} .apbPost`;
	const sliderPostsSl = `${mainSl} .apbSliderPosts`;
	const postReadMoreSl = `${postSl} .apbReadMore`;
	const postTitleSl = `${postSl} .apbTitle`;
	const postMetaSl = `${postSl} .apbMeta`;
	const paginationSl = `${mainSl} .apbPagination`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', metaTypo)?.googleFontLink}
		${getTypoCSS('', excerptTypo)?.googleFontLink}
		${getTypoCSS('', readMoreTypo)?.googleFontLink}
		${getTypoCSS(`${postTitleSl}, ${postTitleSl} a`, titleTypo)?.styles}
		${getTypoCSS(`${postMetaSl}, ${postMetaSl} *, ${postSl} .apbThumbCats`, metaTypo)?.styles}
		${getTypoCSS(`${postSl} .apbExcerpt`, excerptTypo)?.styles}
		${getTypoCSS(`${postReadMoreSl} a`, readMoreTypo)?.styles}

		${paginationSl} .apbPagePrev,
		${paginationSl} .apbPageNumber,
		${paginationSl} .apbPageNext{
			font-size: 15px;
			${getColorsCSS(paginationColors)}
			padding: ${getSpaceCSS(paginationPadding)};
			margin: 0 calc( ${paginationSpacing} / 2 );
		}
		@media (max-width: 576px) {
			${paginationSl} .apbPagePrev,
			${paginationSl} .apbPageNumber,
			${paginationSl} .apbPageNext{
				font-size: 12px;
				padding: ${getSpaceCSS(paginationPadding)?.split(' ').map(v => `calc( ${v} / 2 )`).join(' ')};
				margin: 0 calc( ${paginationSpacing} / 4 );
			}
		}
		${paginationSl} .apbPagePrev:hover,
		${paginationSl} .apbPageNumber:hover,
		${paginationSl} .apbPageNext:hover,
		${paginationSl} .apbActivePage{
			${getColorsCSS(paginationHovColors)}
		}

		${postSl}{
			${getBorderCSS(border)}
		}
		${mainSl} .apbDefault,
		${mainSl} .apbSideImage{
			text-align: ${contentAlign};
			${getBackgroundCSS(contentBG)}
		}

		${postSl} .apbText{
			padding: ${getSpaceCSS(contentPadding)};
		}
		${mainSl} .apbOverlay .apbText{
			${getBackgroundCSS(contentBG)}
			align-items: ${'left' === contentAlign ? 'flex-start' : 'right' === contentAlign ? 'flex-end' : 'center' === contentAlign ? 'center' : ''}
		}

		${postTitleSl}, ${postTitleSl} a{
			text-align: ${contentAlign};
			color: ${titleColor};
		}
		${postTitleSl}{
			margin: ${getSpaceCSS(titleMargin)};
		}
		${postMetaSl}{
			justify-content: ${'left' === contentAlign ? 'flex-start' : 'right' === contentAlign ? 'flex-end' : 'center'};
			color: ${metaTextColor};
			margin: ${getSpaceCSS(metaMargin)};
		}
		${postMetaSl} a{
			color: ${metaLinkColor};
		}
		${postMetaSl} .dashicons{
			color: ${metaIconColor};
		}
		${postSl} .apbThumb img, ${postSl}.apbOverlay img{
			object-fit: ${fImgFitting};
		}
		${postSl} .apbThumbCats a{
			${getColorsCSS(metaColorsOnImage)}
		}
		${postSl} .apbExcerpt{
			text-align: ${excerptAlign};
			color: ${excerptColor};
			margin: ${getSpaceCSS(excerptMargin)};
		}
		${postReadMoreSl}{
			text-align: ${readMoreAlign};
		}
		${postReadMoreSl} a{
			${getColorsCSS(readMoreColors)}
			padding: ${getSpaceCSS(readMorePadding)};
			${getBorderCSS(readMoreBorder)}
		}
		${postReadMoreSl} a:hover{
			${getColorsCSS(readMoreHovColors)}
		}

		${mainSl} .apbGridPosts,
		${mainSl} .apbGrid1Posts{
			grid-gap: ${rowGap}px ${columnGap}px;
			align-items: ${false === isContentEqualHight ? 'start' : 'initial'};
		}

		${sliderPostsSl},
		${sliderPostsSl} .swiper-slide article{
			min-height: ${sliderHeight};
		}
		${sliderPostsSl} .swiper-pagination .swiper-pagination-bullet{
			background: ${sliderPageColor};
			width: ${sliderPageWidth};
			height: ${sliderPageHeight};
			${getBorderCSS(sliderPageBorder)}
		}
		${sliderPostsSl} .swiper-button-prev,
		${sliderPostsSl} .swiper-button-next{
			color: ${sliderPrevNextColor};
		}
	`.replace(/\s+/g, ' ')
	}} />
}
export default Style;